import React, { memo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Carousel from 'react-alice-carousel';
import Img from 'gatsby-image';
import Section, { P } from './styled';
import Title from '../SectionTitle';
import carrierList from './carrierList';
import { useImagesFluid } from '../../hooks/useImages';
import { PRIMARY } from '../../utils/colors';

const HomeShippingCarrier = () => {
  const images = useImagesFluid({ names: Object.keys(carrierList) });
  return (
    <Section>
      <Container>
        <Row className="row align-items-center">
          <Col>
            <Title size="small">As melhores transportadoras estão aqui</Title>
          </Col>
        </Row>
        <Row className="row mt-5">
          <Col xs="12">
            <Carousel
              dotsDisabled
              buttonsDisabled
              mouseDragEnabled
              autoPlayInterval={2000}
              autoPlayDirection="ltr"
              stopAutoPlayOnHover={false}
              autoPlay
              infinite
              responsive={{
                0: {
                  items: 3,
                },
                728: {
                  items: 6,
                },
              }}
              items={images.map(({ node }) => (
                <>
                  <Img
                    fluid={node.fluid}
                    key={node.fluid.originalName}
                    alt={node.fluid.originalName}
                    style={{ width: '100px', height: '50px' }}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                  <P>{carrierList[node.fluid.originalName]}</P>
                </>
              ))}
            />
          </Col>
        </Row>
        <Row className="row align-items-center mt-5 text-center">
          <Col>
            <Title component="h5" size="small" color={PRIMARY}>
              e +100 transportadoras
            </Title>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default memo(HomeShippingCarrier);
