import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { Row, Col } from 'reactstrap';
import { StyledArrowWrapper, StyledSubtitle } from './styled';
import ArrowRight from '../../assets/svgs/arrow.svg';
import ArrowLeft from '../../assets/svgs/arrowLeft.svg';

const RIGHT = 'right';

const HomeHowToItem = memo(
  ({ Image, text, title, align, showCTA, showArrow }) => {
    const order = align === RIGHT ? 0 : 2;
    const Arrow = align === RIGHT ? ArrowRight : ArrowLeft;
    const getClassNames = () =>
      classnames('row mt-5 align-items-center justify-content-center', {
        'justify-content-sm-end': align === RIGHT,
        'justify-content-sm-start': align !== RIGHT,
      });
    return (
      <>
        {showArrow && (
          <StyledArrowWrapper>
            <Arrow />
          </StyledArrowWrapper>
        )}
        <Row className={getClassNames()} noGutters>
          <Col md={{ size: 1, order: 3 }} className="hide-xs" />
          <Col xs={{ size: 7, order: 4 }} md={{ size: 3, order: 1 }}>
            <Image />
          </Col>
          <Col xs="12" md={{ size: 4, order }}>
            <StyledSubtitle>{title}</StyledSubtitle>
            <p>{text}</p>
            {showCTA && (
              <Link className="btn btn-secondary" to="/como-calcular-frete">
                Saiba mais
              </Link>
            )}
          </Col>
        </Row>
      </>
    );
  }
);

HomeHowToItem.propTypes = {
  Image: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
  showCTA: PropTypes.bool.isRequired,
  showArrow: PropTypes.bool.isRequired,
};

export default HomeHowToItem;
